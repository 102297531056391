import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'


const NotFoundPage = () => (
  <Layout>
    <div id="main">
    <section id="content" className="main">
    <h1>Pagina non esistente</h1>
    <p>Riprova o in alternativa torna alla Homepage</p>
    <div>
      <Link to="/index2" className="button">
        Homepage
      </Link>
    </div>
    </section>
    </div>
  </Layout>
)

export default NotFoundPage
